@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.services__wrapper-pedestal {
	.services__wrapper {
		position: relative;
		z-index: 1;
		padding: 20px;
		max-width: 600px;
		margin: 0 auto;
		@media(min-width: 1200px) {
			padding: 0px 10px 20px;
		}
		&:hover, &.current {
			.icon__btn {
				> .before {
					animation: pulse 1.5s infinite;
					animation-delay: 0.3s;
				}
				> .after {
					animation: pulse 1.5s infinite;
				}
			}
			&:after {
				opacity: 1;
			}
			.btn {
				bottom: 0;
				opacity: 1;
			}
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $white;
			border-radius: 30px;
			box-shadow: 0 6px 30px 0 rgba($black, 0.12);
			transition: all .4s ease;
			z-index: -1;
      opacity: 0;
			@media(min-width: 1200px) {
				top: -20px;
				left: -10px;
				right: -10px;
				bottom: -20px;
			}
		}
		.icon__btn {
			margin-bottom: 20px;
			@media(min-width: 1200px) {
				margin-bottom: 10px;
			}
		}
		.btn {
			transition: all .4s ease-in-out;
			margin-top: 15px;
			@media(min-width: 1200px) {
				opacity: 0;
				position: absolute;
				bottom: -10px;
				left: 50%;
				margin: 0;
				transform: translate(-50%, 100%);
			}
		}
		&.left {
			@media(min-width: 1200px) {
				padding-left: 20px;
				.inline-wrap {
					justify-content: flex-start;
				}
				.icon__btn {
					margin-right: 35px;
				}
			}
		}
		&.right {
			@media(min-width: 1200px) {
				padding-right: 20px;
				.inline-wrap {
					justify-content: flex-end;
				}
				.icon__btn {
					margin-left: 35px;
					order: 1;
				}
			}
		}
		&.center {
			@media(min-width: 1200px) {
				padding: 0px 30px 50px;
				&:after {
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
				.icon__btn {
					transform: translateY(-50%);
					margin: 0px 0 0;
				}
				.btn {
					transform: translate(-50%, 50%);
				}
			}
		}
  }
  &_light{
    .services__wrapper h1,
    .services__wrapper h2,
    .services__wrapper h3,
    .services__wrapper h4,
    .services__wrapper h5,
    .services__wrapper h6{
      color: #fff;
    }
    .services__wrapper:hover,
    .services__wrapper.current{
      h1,h2,h3,h4,h5,h6{
        color: #000;
      }
    }
  }
}

.services__wrapper-honeycomb {
	.services__wrapper {
		max-width: 500px;
		margin: 50px auto;
		@media(min-width: 992px) {
			margin: 0 auto;
			margin-top: -30px;
			&.center .icon__cover {
				transform: translateY(-20px);
			}
		}
		&:hover, &.current {
			.icon__btn {
				> .before {
					animation: pulse 1.5s infinite;
					animation-delay: 0.3s;
				}
				> .after {
					animation: pulse 1.5s infinite;
				}
			}
			.divide:after {
				opacity: 1;
			}
			.main-text {
				opacity: 1;
			}
			.btn-width {
				&:before {
					opacity: 0;
				}
				&:after {
					background: $white;
					width: 100%;
				}
				span {
					opacity: 1;
				}
			}
		}
		/* .icon__cover {
		}*/
		p {
			opacity: 0.5;
		}
		.divide {
			display: block;
			height: 0;
			width: 100%;
			border-top: 1px dashed $white;
			margin: 24px 0;
			position: relative;
			z-index: 1;
			&:after {
				content: '';
				position: absolute;
				top: -1px;
				left: 0;
				right: 0;
				height: 2px;
				background: $white;
				opacity: 0;
				transition: all .4s ease;
			}
		}
		.btn-width {
			padding: 15px 54px;
			position: relative;
			z-index: 1;
			&:before {
				content: '\f105';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: $white;
				font-family: "Font Awesome\ 5 Free";
				font-size: 16px;
				transition: all .4s ease;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				transition: all .4s ease;
				border: 2px solid $white;
				border-radius: 10px;
				width: 55px;
				z-index: -1;
			}
			span {
				color: var(--brand-primary);
				display: inline-block;
				opacity: 0;
				transition: all .2s ease;
				overflow: hidden;
			}
		}
	}
}

.services__wrapper-hexagon .services__wrapper {
	&:hover, &.current {
		.hexagon {
			> .after {
				opacity: 1;
				transform: scale(1);
			}
			.cover {
				transform: translateY(-20px);
			}
			> div > *:not(.btn) {
				color: $white;
			}
			.btn {
				opacity: 1;
			}
    }
  }
  @media screen and (max-width: 500px){ 
    .hexagon {
			> .after {
				opacity: 1;
				transform: scale(1);
			}
			.cover {
				transform: translateY(-20px);
			}
			> div > *:not(.btn) {
				color: $white;
			}
			.btn {
				opacity: 1;
			}
    }
  }
}

.services__wrapper{
  position: relative;
  @media screen and (max-width: 992px){ 
    margin-bottom: 20px;
    text-align: center;
  }
  .services__wrapper-shadow{
    position: absolute;
    width: 214px;
    height: calc(100% - 4px);
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0px 30px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    transition: .3s ease opacity;
    opacity: 1;
    >span:nth-child(1){
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: rotate(-60deg);
      box-shadow: 0 0px 30px rgba(0, 0, 0, 0.12);
      transition: .3s ease opacity;
      opacity: 1;
    }
    >span:nth-child(2){
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: rotate(60deg);
      box-shadow: 0 0px 30px rgba(0, 0, 0, 0.12);
      transition: .3s ease opacity;
      opacity: 1;
    }
    @media screen and (max-width:500px){ 
      display: none;
    }
  }
  .highlight-massive .after{
    background-color: #E8E8E8;
    height: 2px;
    top: 100%;
  }
  &:hover,
  &.current{
    .services__wrapper-shadow{
      opacity: 0;
    }
    .highlight-massive .after{
      background-color: var(--brand-primary);
    }
  }
}

.icon__cover {
  width: 112px;
  height: 112px;
  border-radius: 30px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 30px 0 rgba($black, 0.12);
  margin: 0 auto 40px;
  .brk-bordered-theme &{
    border-radius: var(--b-radius);
  }
  .icon__btn {
    font-size: 28px;
    width: 65px;
    height: 65px;
    background: var(--brand-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 6px 30px 0 rgba(var(--brand-primary-rgb), 0.5);
  }
}

// services-architecture/info
.services-architecture {
	padding: 15px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__icon {
		width: 70px;
		
		i {
			vertical-align: 0;
			color: var(--brand-primary);
			font-size: rem(68);
		}
	}
	
	&__container {
		width: calc(100% - 70px);
		padding-left: 30px;
	}
}

.services-architecture-bg {
	padding: 40px 35px;
	display: flex;
	flex-wrap: wrap;
	background: no-repeat center center;
	background-size: cover;
	position: relative;
  height: 100%;
  background-color: #f1f5f9;
  &_wide{
    padding: 40px 30px;
  }

	&__before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	&__icon {
		width: 70px;
		position: relative;
		z-index: 3;

		i {
			vertical-align: 0;
			color: #fff;
			font-size: rem(68);
		}
	}

	&__container {
		width: calc(100% - 70px);
		padding-left: 30px;
		position: relative;
		z-index: 3;
		color: #ffffff;
	}
}

.services-info {
	border: 1px solid rgba(255,255,255,.3);
	border-radius: 4px;

	&__content {
		padding: 25px 30px;
		display: flex;
		border-right: 1px solid rgba(255,255,255,.3);

		@media (max-width: 991px) {
			border-bottom: 1px solid rgba(255,255,255,.3);
			border-right: none;
		}
	}

	&__icon {
		padding-top: 11px;

		i {
			font-size: rem(28);
		}
	}

	&__text {
		padding-left: 23px;
	}

	> .row {
		> .col,
		> [class*="col-"] {
			&:last-child {
				.services-info__content {
					border-right: none;

					@media (max-width: 991px) {
						border-bottom: none;
					}
				}
			}
		}
	}
}

.brk-services-icon{
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 35px;

  &__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    i{
      font-size: 83px;
      color: var(--brand-primary);
    }
  }
  &__title{
    text-align: center;
    padding-bottom: 22px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(39, 39, 39, .1);
  }
}